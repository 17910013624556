import React from 'react';
import AnimatedImages from './Animated';
import './App.css';
import './styles.css';
import PulsatingArrow from './arrow';

function App() {
  return (
    <div className="container">
      <h1 className="playfulVibrantStyle" style={{paddingBottom:'150px'}}>Na Felicitare</h1>
      <PulsatingArrow/>
      <AnimatedImages/>
    </div>
  );
}

export default App;
