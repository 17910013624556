import React from 'react';
import './styles.css';  // Adjust the path if necessary

const PulsatingArrow = () => {
  return (
    <div className="arrow-container">
      <div className="heart"></div>
      <div className="arrow">
        &darr;
      </div>
    </div>
  );
};

export default PulsatingArrow;
