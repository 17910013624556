// AnimatedImages.tsx
import React, { useState, useEffect } from 'react';
import { useScrollPosition } from './useScrollPosition';
import AnimatedImage from './AnimatedImage';
import Confetti from 'react-confetti';
import anime from 'animejs/lib/anime.es.js';
import './styles.css';  // Make sure this is the path to your styles.css

const images = [
  { src: '/story/door.svg', alt: 'Image 1' },
  { src: '/story/goingIn.svg', alt: 'Image 2' },
  { src: '/story/surpriza.svg', alt: 'Image 3' }
];

const AnimatedImages: React.FC = () => {
  const scrollPosition = useScrollPosition();
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (scrollPosition > 800 && !showConfetti) {  // Adjust 800 to the desired scroll position
      setShowConfetti(true);
      // Added a setTimeout to ensure the DOM is ready
      setTimeout(animateText, 0);
    }
  }, [scrollPosition, showConfetti]);


  const animateText = () => {
    const textWrapper = document.querySelector('.finalText');
    if (textWrapper) {
      textWrapper.innerHTML = textWrapper.textContent!.replace(/\S/g, "<span class='letter'>$&</span>");

      anime.timeline({ loop: false })
        .add({
          targets: '.finalText .letter',
          scale: [4, 1],  // Scale down from 4 to 1
          opacity: [0, 1],  // Fade in
          translateZ: 0,
          easing: "easeOutExpo",
          duration: 950,
          delay: (el, i) => 70 * i
        });
    } else {
      console.error('The .finalText element is not rendered yet');
    }
  };



  return (
    <div>
      {images.map((image, index) => {
        const triggerPosition = index * 500;  // Adjust this value to control when each image animates
        const inView = scrollPosition > triggerPosition;
        return (
          <div style={{ paddingTop: '100px', paddingBottom: '200px' }} key={image.src}>  {/* Added padding here */}
            <AnimatedImage src={image.src} alt={image.alt} inView={inView} />
          </div>
        );
      })}
      <div style={{ minHeight: '100vh', position: 'relative' }}>
        {showConfetti && (
          <div className="finalContainer">
            <div className="confettiContainer">
              <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={400} />
            </div>
            <div className="heart-container">
              <div className="heart-text">La multi ani, iubita mea!</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimatedImages;
