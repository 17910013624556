// AnimatedImage.tsx
import React from 'react';
import './styles.css';  // Ensure styles.css is in the same directory or update the path accordingly

type AnimatedImageProps = {
  src: string;
  alt: string;
  inView: boolean;
};

const AnimatedImage: React.FC<AnimatedImageProps> = ({ src, alt, inView }) => {
  const className = inView ? 'animatedImage inView' : 'animatedImage';
  
  return (
    <img className={className} src={src} alt={alt} />
  );
};

export default AnimatedImage;
